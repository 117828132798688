import React, {Component} from 'react'
import {base, appDatabasePrimaryFunctions} from '../../base';
import { Redirect } from 'react-router-dom'
import { confirmAlert } from '../utils/react-confirm-alert';
import appBackgroundImage from '../../styles/images/background.png';
import sampleAppFrontImage from '../../styles/images/front_icon.png';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/Home.css';
import '../../styles/css/main.css';
import { validateEmail } from '../utils/HelpfulFunction';

class Login extends Component {
    constructor(props) {
        super(props);
        let userEmail;
        try {
          userEmail = sessionStorage.getItem('userEmail') || false;
        } catch (e) {
          userEmail = false
        }
        this.state = {
          redirect: false,
          tenantVariables: {},
          inputtedEmail: '',
          inputtedName: '',
          inputtedZipCode: '',
          inputtedFullAddress: '',
          inputtedPhoneNumber: '',
          userEmail: userEmail,
          modalRules: false,
          iframeModal: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      this.props.loadPage();
      this.getQonsentForm()
    }

    getQonsentForm(){
      const tenantVariables = this.props.variables || {};
      // const smartQontractId = tenantVariables.smartQontractId || "843bd382-0a7c-4ff4-aa61-4e4e12d05ea0";
      const smartQontractId = tenantVariables.smartQontractId || "";
      this.setState({smartQontractId: smartQontractId })
    }

    getURLParameter(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantRulesRef);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    sanitizeEmail(emailAddress){
      //get part of email before @ sign, this has different rules then text after the @
      const firstPartOfEmail = emailAddress.split('@')[0];
      //get rest of email to recombine later
      const secondPartOfEmail = emailAddress.split('@')[1];
      //Removes periods because on some mail clients they can be moved around and we will treat them like a seperate email
      const removedPeriodsFirstPartOfEmail = firstPartOfEmail.replace(/\./g, "");
      //Check if there is a plus in the email
      const splitPluses = removedPeriodsFirstPartOfEmail.split('+');
      let finalFirstPart;
      if (splitPluses.length > 1)
      {
        //if there is a plus cut all the nonsense after the plus, this is important because some email clients allow you to put whatever you want after the plus
        finalFirstPart = splitPluses[0];
      }
      else
      {
        //if no plus continue with periods removed from first part of email
        finalFirstPart = removedPeriodsFirstPartOfEmail;
      }
      //recombine email to make the sanitized version
      const sanitizedEmail = finalFirstPart + "@" + secondPartOfEmail;
      //change to upper so people can sneak by case on me
      return sanitizedEmail.toLowerCase();
    }

    skipLogin(){
      const tenantVariables = this.state.tenantVariables;
      const stringConstants = this.props.stringConstants || {};
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      if(tenantVariables.mandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.ERRORTEXT,
          message: stringConstants.NOAGREERULESANDREGS,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      const randomElement = Math.floor((Math.random() * 100000) + 1);
      const sessionTokenString = Date.now().toString() + randomElement.toString();
      sessionStorage.setItem("tempToken", sessionTokenString);
      this.setState({
        redirect: true,
      })
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    handleDateChange(event) {
      let value = event.target.value;
      if(event.target.name === "month"){
        if(value > 12){
          value = "12"
        }
      } else if(event.target.name === "day"){
        if(value > 31){
          value = "31"
        }
      } else if(event.target.name === "year"){
        if(value > new Date().getFullYear()){
          value = new Date().getFullYear().toString()
        }
      }
      this.setState({[event.target.name]: value});
    }

    isValidDate(s) {
      const bits = s.split('-');
      const d = new Date(bits[0], bits[1] - 1, bits[2]);
      return d && (d.getMonth() + 1) === parseInt(bits[1]);
    }

    handleSubmit(event){
      event.preventDefault();
      const inputtedEmail = this.state.inputtedEmail.trim().toLowerCase();
      let htmlParameterEmail = this.getURLParameter("email");
      if(htmlParameterEmail && !validateEmail(htmlParameterEmail)){
        htmlParameterEmail = null
      }
      const tenantVariables = this.props.variables;
      const inputtedName = this.state.inputtedName.trim();
      const year = this.state.year;
      let month = this.state.month;
      let day = this.state.day;
      let inputtedBirthday = sessionStorage.getItem('birthday') || "";
      const inputtedZipCode = this.state.inputtedZipCode.trim();
      const inputtedFullAddress = this.state.inputtedFullAddress.trim();
      const inputtedPhoneNumber = this.state.inputtedPhoneNumber.trim();
      const inputtedHowYouHeard = this.state.howYouHeard || "";
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      const agree_to_promotionRef = this.refs.agree_to_promotion.checked;

      if(inputtedEmail === "" && !htmlParameterEmail && !tenantVariables.doNotCollectEmai){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: "Please Enter An Email!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(!validateEmail(inputtedEmail) && !htmlParameterEmail && !tenantVariables.doNotCollectEmail){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Unable to read email, please check to make sure it is entered correctly",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(!tenantVariables.doNotCollectEmail && (tenantVariables.allowList || tenantVariables.blockList)){
        const lowerCaseEmail = inputtedEmail.toLowerCase();
        const emailDomain = lowerCaseEmail.split('@')[1];
        let allowListArray = [];
        let blockListArray = [];
        if(tenantVariables.allowList){
          allowListArray = tenantVariables.allowList.split(" ");
        }
        if(tenantVariables.blockList){
          blockListArray = tenantVariables.blockList.split(" ");
        }
        if(tenantVariables.allowList && allowListArray.indexOf(lowerCaseEmail) === -1 && allowListArray.indexOf(emailDomain) === -1){
          confirmAlert({
            variables: tenantVariables,
            title: 'Email Error',
            message: "This Email Address Not Allowed",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        } else if(tenantVariables.allowList && (allowListArray.indexOf(lowerCaseEmail) !== -1 || allowListArray.indexOf(emailDomain) === -1)) {
          console.log("Allow override")
        } else if(tenantVariables.blockList && (blockListArray.indexOf(lowerCaseEmail) !== -1 || blockListArray.indexOf(emailDomain) !== -1)){
          confirmAlert({
            variables: tenantVariables,
            title: 'Email Error',
            message: "This Email Address Not Allowed",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        }
      }
      const allowedAge = tenantVariables.allowedAge || 21;
      if(tenantVariables.collectBirthday && tenantVariables.formBirthday && (!year || !month || !day)){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: "Please Enter A Birthday!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(tenantVariables.collectBirthday && tenantVariables.formBirthday) {
        if (year.length < 4) {
          confirmAlert({
            variables: tenantVariables,
            title: 'Missing Info',
            message: "Please make sure the year is 4 digits!",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        }
        if(month && month.length < 2){
          month = "0" + month
        }
        if(day && day.length < 2){
          day = "0" + day
        }
        inputtedBirthday = year + "-" + month + "-" + day;
        if (isNaN(new Date(inputtedBirthday).getTime()) || !this.isValidDate(inputtedBirthday)) {
          confirmAlert({
            variables: tenantVariables,
            title: 'Missing Info',
            message: "Invalid date entered, make sure the date exists",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        }
        const age = ~~((new Date().getTime() - +new Date(inputtedBirthday)) / (31557600000))
        if (age < allowedAge) {
          confirmAlert({
            variables: tenantVariables,
            title: 'Input Error',
            message: "Your are not old enough to participate",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        }
      }

      if(tenantVariables.collectName && inputtedName === ""){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter A Name!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(tenantVariables.collectName && (inputtedName === "" || inputtedName.split(' ').length <= 1 || inputtedName.length > 35)){
        confirmAlert({
          variables: tenantVariables,
          title: 'Input Error',
          message: "Must include a last name, and can't be longer than 35 characters!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber === ""){
        if(!tenantVariables.optionalPhoneNumber){
          confirmAlert({
            variables: tenantVariables,
            title: "Missing Info",
            message: "Please enter a phone number.",
            buttons: [
              {
                label: "OK",
              }
            ]
          })
          return;
        }
      } else if(tenantVariables.collectPhoneNumber && !/^\d+$/.test(inputtedPhoneNumber)){
        confirmAlert({
          variables: tenantVariables,
          title: "Missing Info",
          message: "Please enter a valid phone number that is only numbers and exactly 10 digits long",
          buttons: [
            {
              label: "OK",
            }
          ]
        })
        return;
      } else if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber.length !== 10){
        confirmAlert({
          variables: tenantVariables,
          title: "Missing Info",
          message: "Please enter a valid phone number that is only numbers and exactly 10 digits long",
          buttons: [
            {
              label: "OK",
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectZipCode && inputtedZipCode === ""){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: 'Please Enter A Zip Code!',
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(tenantVariables.collectZipCode && !tenantVariables.doNotVerifyAmericanZipCode && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(inputtedZipCode)){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: "Please enter a valid 5-digit U.S. Zip Code (Ex. 83684)",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectFullAddress && inputtedFullAddress === ""){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: "Please enter a full address.",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectHowYouHeard && tenantVariables.howYouHeardOptions && tenantVariables.howYouHeardOptions.length > 0 && !inputtedHowYouHeard){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: "Please select an option from the dropdown",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }

      if(!tenantVariables.noMandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: tenantVariables,
          title: 'Missing Info',
          message: "Please agree to rules and regulations!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }

      if(tenantVariables.doNotCollectEmail){
        this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress, inputtedHowYouHeard)
        return
      }
      if(!htmlParameterEmail) {
        confirmAlert({
          variables: tenantVariables,
          title: "Confirm Email",
          message: inputtedEmail,
          buttons: [
            {
              label: "NO",
            },
            {
              label: "YES",
              onClick: async () => {
                this.setState({
                  loading: true
                })
                this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress, inputtedHowYouHeard)
              }
            }
          ]
        })
      } else {
        this.props.setPassed(true);
        this.createUser(htmlParameterEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress, inputtedHowYouHeard);
      }
    }

    createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, inputtedFullAddress, inputtedHowYouHeard){
      let base64EncodedEmail = appDatabasePrimaryFunctions.ref().push().key;
      let userId = this.getURLParameter("userid");
      const tenantVariables = this.props.variables || {};
      let sanitizedEmail = "";
      if(inputtedEmail){
        try {
          sanitizedEmail = this.sanitizeEmail(inputtedEmail);
        } catch (e) {
          console.log(e)
        }
        base64EncodedEmail = btoa(inputtedEmail);
      } else if(userId){
        base64EncodedEmail = userId;
      }
      const userObject = {};
      userObject['lastSignIn'] = new Date().getTime();
      userObject['email'] = inputtedEmail;
      userObject['sanitizedEmail'] = sanitizedEmail;
      userObject['uid'] = base64EncodedEmail;
      if(inputtedZipCode){
        userObject['zipCode'] = inputtedZipCode;
      }
      if(inputtedName){
        userObject['name'] = inputtedName;
      }
      if(inputtedFullAddress){
        userObject['address'] = inputtedFullAddress;
      }
      if(inputtedHowYouHeard){
        userObject['howYouHeard'] = inputtedHowYouHeard;
      }
      userObject['optIn'] = agree_to_promotionRef;
      if(inputtedBirthday){
        userObject['birthday'] = inputtedBirthday;
      }
      if(inputtedPhoneNumber){
        userObject['phoneNumber'] = inputtedPhoneNumber;
      }
      const vm = this;
      if(tenantVariables.sanitizeEmails && sanitizedEmail && inputtedEmail){
        inputtedEmail = sanitizedEmail;
        base64EncodedEmail = btoa(sanitizedEmail)
        userObject['uid'] = base64EncodedEmail;
      }
      if(this.state.smartQontractId){
          this.setState({
            userObject: userObject,
            iframeModal: true,
            inputtedSavedEmail: inputtedEmail
          });
      } else {
        this.createUserFromUserObject(userObject, inputtedEmail);
      }
    }

    createUserFromUserObject = async (userObject = null, inputtedEmail = false) => {
      return new Promise(async (resolve, reject) => {
        const isThereInputtedEmail = inputtedEmail || this.state.inputtedSavedEmail;
        const firstUserObject = userObject || this.state.userObject;
        const userId = firstUserObject.uid;

        base.update('users/' + userId, {
          data: firstUserObject,
          context: this,
          then: async (err) => {
            if (!err) {
              try {
                if (isThereInputtedEmail) {
                  sessionStorage.setItem('userEmail', isThereInputtedEmail);
                } else {
                  sessionStorage.setItem('userEmail', userId);
                }
                await this.props.setCurrentUser();
                this.setState({redirect: true});
                resolve();
              } catch (e) {
                console.log(e);
                reject("Unable to log you in! Check your settings and try again!");
              }
            } else {
              reject("Oh No! There was an error please try again!");
            }
          },
        });

        if (userId) {
          appDatabasePrimaryFunctions.ref('users/' + userId + '/signUpTime').set(new Date().getTime())
          .catch(function (error) {
            console.log(error);
          });
        }
      });
    }

    handleBirthdayFocus(){
        this.setState({
          birthdayFocused: true
        }, ()=> {
          document.getElementById('year').placeholder ="YYYY"
          document.getElementById('day').placeholder = "DD"
          document.getElementById('month').placeholder = "MM"
        })
      }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    async acceptTerms(){
        try {
          await this.createUserFromUserObject();
        } catch (e) {
          console.log("Error Saving Users: ", e)
          alert(e.message)
          return;
        }
        const response = await this.props.qonsentApi.createQontract(this.state.smartQontractId, {'primaryEmailAddress': this.state.inputtedSavedEmail});
        console.log(response)
        this.setState({redirect: true});
    }

    render() {
      const { redirect } = this.state;
      const tenantVariables = this.props.variables || {};
      const htmlParameterEmail = this.getURLParameter("email");
      const tenantRules = this.state.tenantRules || {};
      const link = tenantRules.rulesAndRegsLink;
      const rulesRegsText = tenantRules.rulesAndRegsText || "I agree to the rules and regs";
      const howToPlayLink = tenantRules.howToPlayLink || "";
      let howToPlayText = tenantRules.howToPlayText || "";
      const promotionText = tenantVariables.promotionText || "Check To Opt-In To More Offers From Us";
      const howYouHeardOptions = tenantVariables.howYouHeardOptions || [];
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      if(!verifiedAge){
        return (
            <Redirect to="/age_gate" />
        )
      }
      if (redirect || this.state.userEmail) {
        this.props.setCurrentUser();
        return (
            <Redirect to="/" />
        )
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesPopUpHeader = tenantRules.rulesPopUpHeader || "Rules & Regs";
      const rulesPopUpText = tenantRules.rulesPopUpText || "";
      let showHowToPlayButton = false;
      if(howToPlayText || howToPlayLink){
        showHowToPlayButton = true
      }

      if(process.env.REACT_APP_FIREBASE_PROJECT_ID === "qonsent-demo-project"){
        return <iframe src="https://dev-app.withyourconsent.com/app?app=8d2b80e1-ab66-4046-8647-8d54e34c059e&mode=preview&domain=https://dev-app.withyourconsent.com&random=1687985815809#?page=form/pii" frameBorder="0" width="100%" style={{ height: '100vh' }}/>
      }

      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            <div className="flex-content-container-home">
              <div className="intro-container-home">
                <div className="hero-text-container" style={{paddingTop:0}}>
                  <img src={frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <div className="container-out">
                  <div className="question-box question-form" style={{padding:'15px'}}>
                    <h4 style={{fontFamily: "Oswald", fontWeight: 700, display: htmlParameterEmail || tenantVariables.doNotCollectEmail ? "none":""}}>Enter your email to play</h4>
                    <form onSubmit={this.handleSubmit} id="user-values-form">
                      <div className="input-group" style={{display: tenantVariables.doNotCollectEmail || htmlParameterEmail ? 'none' : ""}}>
                        <input id="email" name="inputtedEmail" type="email" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Email" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectName ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="name" name="inputtedName" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder="Name" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectPhoneNumber ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="phoneNumber" name="inputtedPhoneNumber" type="tel" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder="Phone Number" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectZipCode ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="zipCode" name="inputtedZipCode" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder="Zip Code" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectFullAddress ? '' : "none"}}>
                        <div style={{width:"100%", height:10}}/>
                        <input id="fullAddress" name="inputtedFullAddress" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder="Mailing Address" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectBirthday && tenantVariables.formBirthday ? '' : 'none', border: "1px solid #ced4da", borderRadius: "0.25rem", marginTop: 10}}>
                        <input value={this.state.month} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("day").focus()} name="month" id="month" onChange={(e)=> this.handleDateChange(e)} className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} placeholder="Birthday" maxLength="2" pattern="\d*"/>
                        {this.state.birthdayFocused &&
                            <>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.day} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("year").focus()} name="day" id="day" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onChange={(e)=> this.handleDateChange(e)} onFocus={(e) => this.handleBirthdayFocus()} maxLength="2" pattern="\d*"/>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.year} name="year" id="year" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} maxLength="4" pattern="\d*" onChange={(e)=> this.handleDateChange(e)}/>
                            </>
                        }
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectHowYouHeard && howYouHeardOptions && howYouHeardOptions.length > 0 ? '' : 'none', borderRadius: "0.25rem", marginTop: 10,fontFamily: "Oswald"}}>
                        <select className="custom-select" aria-label="Default select example" onChange={this.handleChange} name="howYouHeard">
                          <option selected value="">{tenantVariables.howDidYouHearAboutUsQuestion || "How Did You Hear About This?"}</option>
                          {
                            howYouHeardOptions.map(function (data) {
                              return <option value={data}>
                                {data}
                              </option>
                            })
                          }
                        </select>
                      </div>
                      <div style={{display:!tenantVariables.noMandatoryTermsAndConditions?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:15}}>
                          <input type="checkbox" ref="agree_to_rules_regs" style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeRulesAndRegsId"><strong><u><span onClick={()=>{link?window.open(link, '_blank'):this.toggleRules()}}>{rulesRegsText}</span></u></strong></label>
                        </div>
                      </div>
                      <div style={{display:tenantVariables.collectOptIn?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>
                          <input type="checkbox" ref="agree_to_promotion" defaultChecked={!tenantVariables.notDefaultCheckedOptIn && tenantVariables.collectOptIn} style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeToPromotionId">{promotionText}</label>
                        </div>
                      </div>
                      <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>LET'S PLAY!</strong></button>
                    </form>
                    <button style={{display: showHowToPlayButton?"":"none", backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-rules" onClick={()=>{howToPlayText? this.toggle():window.open(howToPlayLink, '_blank');}}><strong>How To Play</strong></button>
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader/>
              <ModalBody style={{textAlign:"center"}}>
                <div className="container-out" style={{paddingLeft: 20, paddingRight: 20}}>
                  <span dangerouslySetInnerHTML={{ __html:howToPlayText}}/>
                  <button className="btn btn-play" onClick={() => { this.toggle(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>OK</button>
                </div>
              </ModalBody>
              <ModalFooter style={{borderTop:'none'}}/>
            </Modal>
            <Modal isOpen={this.state.iframeModal} style={{width: '100%', margin: 'auto', overflow: 'hidden'}} id="myModal">
              <ModalBody style={{width: '100%', padding: 20}}>
                <iframe
                    src="https://stage-app.withyourconsent.com/app?domain=https://stage-app.withyourconsent.com&mode=preview&_r=1698685719425&app=a415055b-31a0-40bf-b897-3cd3e77a4cd8#q"
                    frameborder="0"
                    style={{
                      width: '100%',
                      height: '375px',
                      display: 'block',
                      margin: 'auto'
                    }}
                />
                <div className="container-fluid" style={{padding: '8px'}}>
                  <div className="row">
                    <div className="col">
                      <button className="btn btn-primary btn-block" type="button" onClick={() => this.acceptTerms()}>Accept</button>
                    </div>
                    <div className="col">
                      <button className="btn btn-secondary btn-block" type="button" onClick={() => this.setState({iframeModal: false, userObject: null, inputtedSavedEmail: null})}>Decline</button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal" toggle={this.toggleRules}>
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                  {rulesPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Login;
